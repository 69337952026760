.up_button {
    position: fixed;
    bottom: 90px;
    right: 30px;
    background-color: #e5e5e5;
    border: 1px solid #191919;
    border-radius: 50%;
    font-size: 1.5rem;
    width: 50px;
    height: 50px;
}
.up_button:hover {
    cursor: pointer;
    background-color: #b1a7a5;
}

.up_button__lines {
    margin: 0 auto;
}

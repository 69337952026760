.catalog_product__container {
  margin-bottom: 3rem;
}
.catalog_product_wrapper {
  max-width: 95vw;
  padding: 1rem 0;
  /* height: 65vh; */
  margin: 1rem auto 3rem auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 1px solid #191919;
  border-radius: 1em;
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  -ms-border-radius: 1em;
  -o-border-radius: 1em;
  background-color: #e5e5e7;
  /* background-color: azure; */
}

.catalog__description_lonking__wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.catalog_product_elem_title {
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  border: 1px solid #191919;
  /* padding: 0.753rem  3.53rem 0.753rem 1.53rem; */
  padding: 0.753rem 0;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}
.catalog_product_elem_title:hover {
  cursor: pointer;
  background-color: #191919;
  color: #e5e5e5;
  transform: skew(-10deg);
  -webkit-transform: skew(-10deg);
  -moz-transform: skew(-10deg);
  -ms-transform: skew(-10deg);
  -o-transform: skew(-10deg);
}
.catalog_product__description_wrapper {
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.catalog_product__description_left, .catalog_product__description_right {
  width: 47%;
  /* max-width: 29rem; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
}
.catalog_product__text_description_columns {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 0.7rem; */
}
.catalog_product__description {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.catalog_product__description_text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* border-left: 1px solid #191919;
  border-right: 1px solid #191919;
  border-bottom: 1px solid #191919;
  border-radius: 1rem; */
}
.text_description_columns_item_right {
  width: 50%;
}
.text_description_columns_item {
  width: 45%;
}
.text_description_columns_item_wrapper {
  min-height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #191919;
  font-size: 0.8rem;
  font-weight: 400;
}

.text_description_columns_item_wrapper:last-child { 
  border-bottom: none;
}

.catalog_product__description_title_text {
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
}

.catalog_product_img {
  margin: 0 auto;
  scale: 1.1;
  width: 25vw;
}

.catalog_product_title {
  text-align: center;
}
.catalog_main_desc {
  width: 95%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.catalog_main_desc:nth-child(2n) {
  width: 95%;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.catalog_main_desc_text {
  min-width: 25em;
  max-width: 50%;
  margin: auto 0;  
}
.catalog_main_desc_img {
  margin: auto;
  width: 35em;
}
.catalog_product_slider {
  width: 55%;
  /* height: 50%; */
  margin: 0 auto;
  margin-bottom: 5.5rem;
}

@media(max-width: 1250px) {
  .catalog_product__description_wrapper {
    gap: 2.5rem;
  }
  .catalog_product__description_text {
    font-size: 0.9rem;
  }
  .catalog_product_img {
    scale: 1;
    width: 25vw;
  }
  .catalog_main_desc {
    font-size: 0.8rem;
  }
  .catalog_main_desc_text {
    min-width: 15em;
    max-width: 50%;
  }
  .catalog_main_desc_img {
    margin: auto;
    width: 28em;
  }
  .catalog_product_slider {
    width: 65%;
  }
}

@media(max-width: 850px) {

  .catalog_product_wrapper {  
    gap: 3rem;
  }
  .catalog_product__description_title_text {
    font-size: 0.9rem;
    width: 100%;
  }
  .catalog_product_title {
    font-size: 1.1rem;
  }
  .catalog__description_lonking {
    font-size: 0.85rem;
  }
  .catalog_main_desc_text {
    margin: 0 auto;
    max-width: 95%;
  }
  .catalog_main_desc_img {
    max-width: 100%;
    margin: 0 auto;
  }
  .catalog_main_desc {
    text-align: justify;
    align-items: center;
  }
  .catalog_product__description {
    justify-content: space-around;
  }
  .catalog_product_slider {
    width: 85%;
    margin-bottom: 3.5rem;
  }
}

@media(max-width: 600px) {
  .catalog_product__description {
    flex-direction: column;
    /* justify-content: ; */
  }
  .catalog_product__description_left, .catalog_product__description_right {
    width: 95%;
  }
}

@media(max-width: 550px) {
  .catalog_product__description_wrapper {
    width: 95%;
    gap: 0rem;
  }
  .catalog_product__description_text {
    font-size: 0.7rem;
  }
  .catalog_product_img {
    width: 50vw;
    margin: 0;
  }
  .catalog_product__description {
    gap: 1rem;
    padding-left: 0;
    margin: 1rem auto;
  }
  .catalog_product_wrapper { 
    gap: 1rem;
  }
  .catalog_product__description_title_text {
    font-size: 0.9rem;
    width: 100%;
  }
  .catalog_product_title {
    font-size: 1.1rem;
  }
  .catalog_main_desc {
    font-size: 0.7rem;
  }
  .catalog_product__description_left, .catalog_product__description_right {
    margin: 0 auto;
  }
  .catalog_product_slider {
    width: 90%;
    margin-bottom: 3.5rem;
  }
}
.contacts__container {
  width: 90vw;
  margin: 3% auto;
  display: flex;
  gap: 5%;
  align-items: center;
}
@media(max-width: 900px) {
  .contacts__container {
    width: 90vw;
    gap: 3%;
  }
}
@media(max-width: 768px) {
  .contacts__container {
    flex-wrap: wrap;
    gap: 50px;
  }
}
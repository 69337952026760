.catalog__container {
    margin-bottom: 3rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.catalog_cards__container {
    margin: 0 auto;
    width: 90vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* flex-direction: column; */
    gap: 5rem;
}

.catalog__card {
    display: flex;
    flex-direction: column;
    width: 25vw;
    height: 50vh;
    background-color: #e5e5e5;
    border-radius: 5%;
}
.catalog__card:first-child {
    background-image: url('../../assets/svg/logos/BENDA.png');
    background-position: center 4rem;
    background-size: 50%;
    background-repeat: no-repeat;
}
.catalog__card:nth-child(2) {
    background-image: url('../../assets/svg/logos/ARIIC.png');
    background-position: center center;
    background-size: 90%;
    background-repeat: no-repeat;
}
.catalog__card:nth-child(3) {
    background-image: url('../../assets/svg/logos/CHANGJIANG.png');
    background-position: center center;
    background-size: 90%;
    background-repeat: no-repeat;
}
.catalog__card:hover {
    cursor: pointer;
    scale: 1.05;
}
.catalog__card_title {
    font-size: 1.5rem;
    text-align: center;
    margin: 0 auto;
    margin-top: 2rem;
}
.catalog__container_title {
    font-size: 1.5rem;
    text-align: center;
}
.catalog__card_img_wrapper {
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    margin: 0 auto;
    padding: 0;
    width: 90%;
    max-height: 35vh;
}
.catalog__card_img {
    margin: 0 auto;
    width: 80%;
    scale: 0.9;
    height: 100%;
}

@media(max-width: 1400px) {
    .catalog__card_img {
        width: 95%
    }
    .catalog__card {
        gap: 0.5rem;
        width: 25vw;
        height: 25rem;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
    }
    .catalog_cards__container {
        gap: 4rem;
    }
}

@media(max-width: 1150px) {
    .catalog__card {
        gap: 0.5rem;
        width: 25vw;
        height: 20rem;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
    }
    .catalog__card_title {
        font-size: 1rem;
    }
}

@media(max-width: 850px) {
    .catalog__card_img {
        width: 99%;
    }
    .catalog__card {
        gap: 0.5rem;
        width: 30%;
        height: 17rem;
    }
}

@media(max-width: 650px) {
    .catalog_cards__container {
        gap: 1rem;
        width: 80%;
    }
    .catalog__card_img {
        width: 99%;
    }
    .catalog__card {
        gap: 0.5rem;
        width: 10rem;
        height: 15rem;
    }
    .catalog__card_title {
        font-size: 0.7rem;
    }
}
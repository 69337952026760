.main_photo__wrapper {
  width: 100%;
  height: 50vw;
  background-image: url('../../../assets/mainPhoto.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  margin-bottom: 1.5rem;
}

.main_photo__title {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  font-size: 2.5rem;
  color: #e5e5e5;
}

.main_title__wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  transform: translate(85%, 3rem);
  -webkit-transform: translate(85%, 3rem);
  -moz-transform: translate(85%, 3rem);
  -ms-transform: translate(85%, 3rem);
  -o-transform: translate(85%, 3rem);
}

.main_photo__text {
  text-align: center;
  width: 65%;
  font-size: 1.5rem;
  color: #e5e5e5;
}

/* Animation */
.main_photo__title {
  animation: typewriter 5s steps(44) 1s 1 normal both,   blinkTextCursor 500ms steps(44) infinite normal;
  -webkit-animation: typewriter 5s steps(44) 1s 1 normal both,   blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter {
  from {width: 0;}
  to {width: 100%;}
}
@keyframes blinkTextCursor {
  from {border-right-color: #191919;}
  to {border-right-color: transparent;}
}

@media(max-width: 1180px) {
  .main_photo__title {
    font-size: 2.1rem;
  }
  .main_photo__text {
    width: 75%;
    font-size: 1.1rem;
  }
}

@media(max-width: 1035px) {
  .main_photo__title {
    font-size: 2.1rem;
  }
  .main_photo__text {
    width: 85%;
    font-size: 1.1rem;
  }
  .main_title__wrapper {
    gap: 0.1rem;
    transform: translate(65%, 1rem);
    -webkit-transform: translate(65%, 1rem);
    -moz-transform: translate(65%, 1rem);
    -ms-transform: translate(65%, 1rem);
    -o-transform: translate(65%, 1rem);
}
}

@media(max-width: 860px) {
  .main_photo__title {
    font-size: 2rem;
  }
  .main_photo__text {
    width: 85%;
    font-size: 1rem;
  }
}

@media(max-width: 768px) {
  /* .main_photo__wrapper { */
    /* background-position: center 1rem; */
    /* margin-bottom: -3rem; */
  /* } */
  .main_photo__title {
    /* margin-top: -10%; */
    font-size: 1.7rem;
  }
  .main_photo__text {
    width: 85%;
    font-size: 0.9rem;
  }
}

@media(max-width: 640px) {
  .main_photo__title {
    font-size: 1.7rem;
  }
  .main_photo__text {
    width: 95%;
    font-size: 0.7rem;
  }
}

@media(max-width: 485px) {
  .main_photo__title {
    font-size: 1.4rem;
  }
  .main_photo__text {
    width: 95%;
    font-size: 0.6rem;
  }
}

@media(max-width: 400px) {
  .main_photo__title {
    /* margin-top: -30%; */
    font-size: 1.4rem;
  }
  .main_photo__text {
    width: 95%;
    font-size: 0.55rem;
  }
}

@media(max-width: 400px) {
  .main_photo__text {
    width: 100%;
    font-size: 0.50rem;
  }
}
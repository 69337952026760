.catalog_forklift__container {
    margin-bottom: 3rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.catalog_forklift_card_price {
    width: 20vw;
    margin: 0 auto;
    text-align: center;
    font-size: 1.3rem;
}

.catalog_forklift_cards__container {
    margin: 0 auto;
    width: 90vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* flex-direction: column; */
    gap: 3rem;
}
.catalog_forklift_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    width: 25vw;
    height: 65vh;
    background-color: #e5e5e5;
    border-radius: 5%;
}
.catalog_forklift_card_text_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    gap: 0.3rem;
}
.catalog_forklift_card:hover {
    cursor: pointer;
    scale: 1.01;
}
.catalog_forklift_card_title {
    font-size: 1.5rem;
    margin: 0 auto;
    text-align: center;
    width: 90%;
}
.catalog_forklift__container_title {
    font-size: 1.5rem;
    text-align: center;
}
.catalog_forklift_card_img {
    margin: 0 auto;
    width: 60%;
    height: 40%;
}

@media(max-width: 1400px) {
    .catalog_forklift_card_img {
        width: 70%;
        height: 50%;
    }
    .catalog_forklift_card {
        gap: 0.5rem;
        width: 25vw;
        height: 25rem;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
    }
    .catalog_forklift__container {
        gap: 1rem;
    }
    .catalog_forklift_cards__container {
        gap: 3rem;
    }
}

@media(max-width: 1150px) {
    .catalog_forklift_card_img {
        width: 80%;
        height: 50%;
    }
    .catalog_forklift_card {
        gap: 0.5rem;
        width: 25vw;
        height: 20rem;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
    }
    .catalog_forklift_card_title {
        font-size: 1rem;
    }
    .catalog_forklift_card_text {
        font-size: 0.75rem;
    }
}

@media(max-width: 850px) {
    .catalog_forklift_card_img {
        width: 70%;
        height: 40%;
    }
    .catalog_forklift_card {
        gap: 0.5rem;
        width: 30%;
        height: 17rem;
    }
    .catalog_forklift_card_title {
        font-size: 0.8rem;
    }

}

@media(max-width: 650px) {
    .catalog_forklift__container_title {
        font-size: 1.1rem;
    }
    .catalog_forklift__container {
        margin: 0 auto;
        gap: 1rem;
        width: 80%;
        margin-bottom: 1.5rem;
    }
    .catalog_forklift_cards__container {
        gap: 1rem;
        width: 100%;
    }
    .catalog_forklift_card_img {
        width: 70%;
    }
    .catalog_forklift_card {
        gap: 1rem;
        width: 10rem;
        height: 15rem;
    }
    .catalog_forklift_card_title {
        font-size: 0.7rem;
    }
    .catalog_forklift_card_text {
        font-size: 0.6rem;
    }
    .catalog_forklift_card_price {
        width: 20vw;
        font-size: 0.7rem;
    }
}
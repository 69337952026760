.information__container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 5%;
  font-size: 18px;
}
.information__contacts_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.information__container {
  display:flex;
  flex-direction: column;
  gap: 10px;
}

@media(max-width: 800px) {
  .information__btn_show_requisites {
    width: 80%;
  }
}
.header__container {
    color: #191919;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 100;
    font-size: 1rem;
    /* border-bottom: 1.5px solid #191919; */
    /* background-image: url('../../assets/img/mainPhoto.png'); */
    background-size: 100%;
}
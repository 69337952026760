.our_products__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}
.our_products__title {
  text-align: center;
  font-size: 2rem;
}
.catalog_cards__container {
  margin: 0 auto;
  width: 90vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* flex-direction: column; */
  gap: 5rem;
  margin-bottom: 5rem;
}
.catalog__card {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  gap: 1rem;
  width: 25vw;
  height: 50vh;
  border: 3px solid #191919;
  /* background-color: #e5e5e5; */

  border-radius: 5%;
}

.catalog__card:first-child {
  background-image: url('../../../assets/svg/logos/BENDA.png');
  background-position: center 4rem;
  background-size: 50%;
  background-repeat: no-repeat;
}
.catalog__card:nth-child(2) {
  background-image: url('../../../assets/svg/logos/ARIIC.png');
  background-position: center center;
  background-size: 90%;
  background-repeat: no-repeat;
}
.catalog__card:nth-child(3) {
  background-image: url('../../../assets/svg/logos/CHANGJIANG.png');
  background-position: center center;
  background-size: 90%;
  background-repeat: no-repeat;
}
.catalog__card:hover {
  cursor: pointer;
  scale: 1.01;
}
.catalog__card_title {
  font-size: 1.5rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 2rem;
}
.catalog__card_img {
  margin: 0 auto;
  width: 90%;
}

@media(max-width: 1400px) {
  .catalog__card_img {
      width: 95%;
  }
  .catalog__card {
      gap: 0.5rem;
      width: 25vw;
      height: 25rem;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
  }
  .catalog_cards__container {
      gap: 4rem;
  }
}

@media(max-width: 1150px) {
  .catalog__card {
      gap: 0.5rem;
      width: 25vw;
      height: 20rem;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
  }
  .catalog__card_title {
      font-size: 1rem;
  }
}

@media(max-width: 850px) {
  .catalog__card_img {
      width: 99%;
  }
  .catalog__card {
      gap: 0.5rem;
      width: 30%;
      height: 17rem;
  }
}

@media(max-width: 650px) {
  .catalog_cards__container {
      gap: 1rem;
      width: 80%;
  }
  .catalog__card_img {
      width: 99%;
  }
  .catalog__card {
      gap: 0.5rem;
      width: 10rem;
      height: 15rem;
  }
  .catalog__card_title {
      font-size: 0.9rem;
  }
}
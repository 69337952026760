.footer_container {
  border-top: 3px solid #191919;;
  padding-top: 1%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 100;
  font-size: 1rem;
  flex-wrap: wrap;
}

.footer__main_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100px;
}

.footer__logo {
  width: 200px;
  height: 100%;
}

/* .footer__main_logo .footer_container__contacts, .footer_container__adress, .footer_container__info {
  width: 30vw;
} */

.footer_container__contacts, .footer_container__adress, .footer_container__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 7rem; */
  gap: 0.3rem;
}

.contacts_textblock, .adress__text {
  /* padding-top: 3%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
}

.footer__navbar_link {
  text-decoration: none;
  color: #191919;
}

.footer__navbar_link:hover {
  cursor: pointer;
  color: #e5e5e5;
}

.adress__text {
  text-align: center;
}

@media(max-width: 945px) {
  .footer_container {
    font-size: 0.95rem;
  }
}

@media(max-width: 768px) {
  .footer_container {
    font-size: 0.95rem;
    gap: 1.5rem;
  }
}

@media(max-width: 615px) {
  .footer_container {
    gap: 25rem;
    row-gap: 1rem;
    font-size: 0.8rem;
  }
  .footer_container__contacts, .footer_container__adress, .footer_container__info {
    width: 55%;
    border-top: 1px solid #191919;
    padding-top: 1rem;
  }
}

@media(max-width: 400px) {
  .footer_container {
    gap: 25rem;
    row-gap: 1rem;
  }

 .footer_container__contacts, .footer_container__adress, .footer_container__info {
    width: 55%;
    border-top: 1px solid #191919;
    padding-top: 1rem;
  }

  .footer__main_logo {
    height: 70px;
  }

}
@font-face {
    font-family: 'Stolzl';
    src: url(../assets/fonts/Stolzl/stolzl_regular.otf);
}
html {
    z-index: -999;
}

body {
    pointer-events: auto !important;
    background-color: #b1a7a5;
    color: #191919;
    font-family: Stolzl;
}

header, nav, footer, a {
    color: #191919;
}
.App {
    /* overflow:scroll; */
    display: flex;
    flex-direction: column;
    /* height: 3000px; */
}

.modal_container {
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
}
.modal_form_wrapper {
  width: 23rem;
  height: 50%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: #5b5553;
  border: 1px solid #b1a7a5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  -ms-border-radius: 1em;
  -o-border-radius: 1em;
}
.modal_close {
  width: 50px; 
  height: 50px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 23rem;
  left: 19rem;
  margin: auto;
  z-index: 557;
}

.payform-tinkoff {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 2px auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 250px;
}
.payform-tinkoff-row {
  margin: 2px;
  border-radius: 4px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 1px solid #DFE3F3;
  padding: 15px;
  outline: none;
  background-color: #DFE3F3;
  font-size: 15px;
}
.payform-tinkoff-row:focus {
  background-color: #FFFFFF;
  border: 1px solid #616871;
  border-radius: 4px;
}
.payform-tinkoff-btn {
  background-color: #FBC520;
  border: 1px solid #FBC520;
  color: #3C2C0B;
}
.payform-tinkoff-btn:hover {
  background-color: #FAB619;
  border: 1px solid #FAB619;
}
.map__container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.map__board {
  border: 2px solid #191919;
}
.map__title {
  font-size: 32px;
  color: #191919;
  font-weight: 700;
  text-align: center;
}
.map {
  width: 55vw;
  height: 60vh;
}

@media(max-width: 800px) {
  .map__container {
    gap: 20px;
  }
}
@media(max-width: 768px) {
  .map {
    width: 80vw;
    height: 80vh;
  }
}
@media(max-width: 500px) {
  .map {
    width: 80vw;
    height: 40vh;
  }
}
.main_advantages__wrapper {
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  /* border-top: 1px solid #191919; */
  border-bottom: 1px solid #191919;
}
.main_advantages__img_and_text {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 1rem;
}
.main_advantages__img {
  width: 45vw;
  height: 65vh;
  scale: 0.7;
}
.main_advantages__text_wrapper {
  /* margin-left: 37rem; */
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* flex-wrap: wrap; */
  /* justify-content: space-around; */
  gap: 1.5rem;
  /* min-height: 35em; */
}

.main_advantages__title {
  font-size: 2rem;
}

.main_advantages__text {
  list-style: none;
  font-size: 1.1rem;
  /* width: 30%; */
}

@media(max-width: 1400px) {
  .main_advantages__img {
    width: 45%;
    height: 45%;
  }
}

@media(max-width: 1000px) {
  .main_advantages__title {
    text-align: center;
    font-size: 1.5rem;
  }
  .main_advantages__text_wrapper {
    padding: 0;
    margin: 0 auto;
    width: 90%;
  }
  .main_advantages__img {
    margin: 0 auto;
    width: 70%;
    max-height: 35rem;
  }
  .main_advantages__img_and_text {
    flex-wrap: wrap;
  }
  .main_advantages__text {
    font-size: 0.9rem;
  }
}

@media(max-width: 400px) {
  .main_advantages__title {
    font-size: 1.1rem;
  }
  .main_advantages__text {
    font-size: 0.8rem;
  }
  .main_advantages__text_wrapper {
    gap: 0.5rem;
  }
}
.top_header__container {
    width: 100%;
    height: 100px;
    padding: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1.5px solid #191919;
}

.top_header__logo_wrapper {
    display: flex;
}
.top_header__logo {
    /* margin-left: 50%; */
    width: 200px;
    height: 100px;
}

.top_header__logo_wrapper:hover {
    animation: rotate 3s infinite linear;
    -webkit-animation: rotate 3s infinite linear;
}

@keyframes rotate {
    0% {
        transform: rotate3d(0);
    }
    50% {
        transform: rotate3d(0, 1, 0, 180deg);
    }
    100% {
        transform: rotate3d(0, 1, 0, 360deg);
    }
}

@media(max-width: 1250px) {
    .top_header__container {
        font-size: 0.9rem;
    }
}
@media(max-width: 1050px) {
    .top_header__container {
        font-size: 0.8rem;
    }
}
@media(max-width: 860px) {
    .top_header__container {
        font-size: 0.71rem;
    }
}
@media(max-width: 600px) {
    .top_header__logo {
        scale: 0.75;
    }
}
@media(max-width: 500px) {
    .top_header__logo {
        width: 150px;
    }
}
.header__navbar {
    /* border-top: 1.5px solid #191919; */
    border-bottom: 1.5px solid #191919;
    /* background-image: url('../../../assets/img/mainPhoto.png'); */
    background-size: 100%;
}

.header__navbar_links {
    display: flex;
    width: 100%;
    padding: 10px 0;
    justify-content: space-evenly;
    align-items: center;
    gap: 5%;
    
}

.header__navbar_link {
    text-decoration: none;
    color: #191919;
}

.header__navbar_link:hover {
    cursor: pointer;
    color: #e5e5e5;
}

@media (max-width: 768px) {
    .header__navbar {
        display: none;
        z-index: 100;
        position: absolute;
        background: #191919;
        right: -100%;
        width: 50%;
    }
    .header__navbar.active {
        height: 100vh;
        display: block;
        animation-name: show;
        animation-duration: 0.5s;
        top: 0%;
        right: 0%;
    }
    .header__navbar.active > .header__navbar_links {
        margin: 0 auto;
        margin-top: 7em;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 25px;
    }
    .header__navbar_link {
        color: #b1a7a5;
    }
}

@keyframes show {
    from {right: -100%;}
    to {right: 0%;}
  }

  @media (max-width: 480px) {
    .header__navbar {
        width: 70%;
    }
}
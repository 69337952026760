.main_info_cards__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.info_cards__wrapper {
  width: 90%;
  margin: 0 auto;
  padding: 1.5rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
}

.info_cards_title {
  margin: 0 auto;
  text-align: center;
  font-size: 2rem;
}

@media(max-width: 768px) {
  .info_cards__wrapper {
    width: 90%;
  }
}

@media(max-width: 726px) {
  .info_cards_title {
    font-size: 1.5rem;
  }
}
.pulse_button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 40px;
  padding: 10px;
  border: none;
  background: #e5e5e5;
  -webkit-box-shadow: 0 5px 35px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  outline: none;
  z-index: 500;
}

.pulse_button:hover .pulse_button__icon {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.pulse_button:hover .pulse_button__text {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 1;
  visibility: visible;
  position: relative;
  color: #191919;
}

.pulse_button__icon {
  display: block;
  width: 30px;
  height: 30px;
  background: url('../../../../assets/svg/phone.svg') center center no-repeat;
  background-size: cover;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.pulse_button__text {
  display: block;
  width: 100%;
  height: 25px;
  font: 10px 'Open Sans', sans-serif;
  color: #e5e5e5;
  text-align: center;
  line-height: 1;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-top: 2px;
  margin-left: -7px;
}

.pulse_button__rings {
  border: 1px solid #e5e5e5;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
  width: auto;
  border-radius: 50%;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: pulse_1;
  animation-name: pulse_1;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  z-index: -1;
}

.pulse_button__rings:nth-child(2) {
  -webkit-animation-name: pulse_2;
  animation-name: pulse_2;
}

.pulse_button__rings:nth-child(3) {
  -webkit-animation-name: pulse_3;
  animation-name: pulse_3;
}

@-webkit-keyframes pulse_1 {
  from {
    opacity: 1;
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
  }
}

@keyframes pulse_1 {
  from {
    opacity: 1;
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
  }
}

@-webkit-keyframes pulse_2 {
  from {
    opacity: 1;
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
  }
}

@keyframes pulse_2 {
  from {
    opacity: 1;
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
  }
}

@-webkit-keyframes pulse_3 {
  from {
    opacity: 1;
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(1.7, 1.7);
    transform: scale(1.7, 1.7);
  }
}

@keyframes pulse_3 {
  from {
    opacity: 1;
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(1.7, 1.7);
    transform: scale(1.7, 1.7);
  }
}

.error__contsiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5em;
  }
  
  .error_block {
    margin-top: 5em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10em;
  }
  .error_number_wrapper {
    background-color: #191919;
    border: 2px solid #191919;
    transform: rotate(-5deg);
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
  }
  .error_number {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 5rem;
    padding: 25px;
    background-color: #191919;
    border: 2px solid #191919;
    color: white;
    transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
  }
  .error_main_block {
    width: 60%;
    display: flex;
    flex-direction: column;
  }
  .error_title {
    color: red;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 2rem;
    text-align: center;
  }
  .error_text_block {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1rem;
    text-align: center;
  }